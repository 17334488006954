var API_ROUTE = "";
var RTAPI_URL = "";

let dev = true;
if (typeof process.env.NODE_ENV !== "undefined") {
  if (process.env.NODE_ENV === "production") {
    dev = false;
  }
}

if (dev) {
  API_ROUTE = "http://" + window.location.hostname + ":3028/api";
  RTAPI_URL = "ws://" + window.location.hostname + ":7105";
} else {
  API_ROUTE = "https://" + window.location.hostname + "/api";
  RTAPI_URL = "wss://" + window.location.hostname + "/ws";
}

//
// API_ROUTE = "https://axses.tlp.innates.my/api";
// RTAPI_URL = "wss://axses.tlp.innates.my/ws";

// API_ROUTE = "https://axses.utmcanselori.innates.my/api";
// RTAPI_URL = "wss://axses.utmcanselori.innates.my/ws";

export const SERVER = {
  API: {
    Login: API_ROUTE + "/AppUsers/login",
    Logout: API_ROUTE + "/AppUsers/logout",
    ChangePassword: API_ROUTE + "/AppUsers/change-password",
    ResetPassword: API_ROUTE + "/AppUsers/reset/password",

    AppUser: {
      MAIN: API_ROUTE + "AppUsers",
      Removed: API_ROUTE + "/AppUsers/delete/user",
      List: API_ROUTE + "/AppUsers/list",
    },

    ProfileUsers: {
      Register: API_ROUTE + "/ProfileUsers/register",
      Removed: (id) => {
        return API_ROUTE + "/ProfileUsers/" + id + "/deleteUser";
      },
      List: API_ROUTE + "/ProfileUsers/list",
      Count: API_ROUTE + "/ProfileUsers/count/user",
      UpdateUserInfo: (id) => {
        return API_ROUTE + "/ProfileUsers/updateUserInfo/" + id;
      },
      UpdateUserId: (id) => {
        return API_ROUTE + "/ProfileUsers/" + id + "/updateUserId";
      },
      UpdateUserName: (id) => {
        return API_ROUTE + "/ProfileUsers/" + id + "/updateUserName";
      },
      UpdateUserEmail: (id) => {
        return API_ROUTE + "/ProfileUsers/" + id + "/updateUserEmail";
      },

      UserRfidCard: (id) => {
        return API_ROUTE + "/ProfileUsers/" + id + "/rfidCardRecord";
      },
      UserPasswordPin: (id) => {
        return API_ROUTE + "/ProfileUsers/" + id + "/passwordPinRecord";
      },
      UserFingerPrint: (id) => {
        return API_ROUTE + "/ProfileUsers/" + id + "/fingerPrintRecord";
      },
      UserFingerPrintCount: (id) => {
        return API_ROUTE + "/ProfileUsers/" + id + "/fingerPrintRecord/count";
      },
      UserFace: (id) => {
        return API_ROUTE + "/ProfileUsers/" + id + "/faceRecord";
      },
      DownloadToDoor: (id) => {
        return API_ROUTE + "/ProfileUsers/downloadUserToDoor/" + id;
      },
      ListDoors: (id) => {
        return API_ROUTE + "/ProfileUsers/" + id + "/listDoors";
      },
      UpdateUserAccessTime: (id, doorId) => {
        return (
          API_ROUTE + "/ProfileUsers/" + id + "/updateUserAccessTime/" + doorId
        );
      },
      UpdateUserAccessDate: (id, doorId) => {
        return (
          API_ROUTE + "/ProfileUsers/" + id + "/updateUserAccessDate/" + doorId
        );
      },

      UpdateUserOrganization: (id, organizationId) => {
        return (
          API_ROUTE +
          "/ProfileUsers/" +
          id +
          "/updateUserOrganization/" +
          organizationId
        );
      },
      UpdateUserModes: (id) => {
        return API_ROUTE + "/ProfileUsers/" + id + "/updateUserModes";
      },

      UserAccessInfo: API_ROUTE + "/AccessDoorMappings",
      UpdateUserAccessInfo: (id) => {
        return API_ROUTE + "/AccessDoorMappings/" + id;
      },

      AddGroup: (id) => {
        return API_ROUTE + "/ProfileUsers/" + id + "/addgroup/to/user";
      },
      RemovedGroup: (id, groupId) => {
        return (
          API_ROUTE +
          "/ProfileUsers/" +
          id +
          "/removegroup/from/user/" +
          groupId
        );
      },
    },
    Dashboard: {
      LatestEntry: API_ROUTE + "/Dashboards/getLatestEntry",
    },
    Doors: {
      MAIN: API_ROUTE + "/Doors",
      SyncTimeZone: API_ROUTE + "/Doors/sync/timezone",
      TodayList: API_ROUTE + "/Doors/today/list",
      Register: API_ROUTE + "/Doors/register",
      Modify: (id) => {
        return API_ROUTE + "/Doors/modify/" + id;
      },
      ModifyLocation: (id) => {
        return API_ROUTE + "/Doors/" + id + "/modify/location";
      },
      ModifySerialId: (id, terminalId) => {
        return API_ROUTE + "/Doors/" + id + "/modify/serialId/" + terminalId;
      },
      OpenDoor: (id) => {
        return API_ROUTE + "/Doors/" + id + "/openDoor";
      },
      RemoveDoor: (id) => {
        return API_ROUTE + "/Doors/" + id + "/removed";
      },
      ListUsers: (id) => {
        return API_ROUTE + "/Doors/" + id + "/listUsers";
      },
      List: API_ROUTE + "/Doors/list",
      CountActive: API_ROUTE + "/Doors/active",
      CountAlert: API_ROUTE + "/Doors/alert",
      CountOpen: API_ROUTE + "/Doors/getDoorOpen",
      Count: API_ROUTE + "/Doors/count",
      ReCountUser: (id, ctotal) => {
        return API_ROUTE + "/Doors/" + id + "/recountuser/" + ctotal;
      },
      AccessParameter: (id) => {
        return API_ROUTE + "/Doors/" + id + "/accessParameter";
      },
      NormalOpenTime: (id) => {
        return API_ROUTE + "/Doors/" + id + "/normalOpenTIme";
      },
      ParameterInfo: (id) => {
        return API_ROUTE + "/Doors/" + id + "/setParameterInfo";
      },
      DownloadToDoor: (id) => {
        return API_ROUTE + "/Doors/" + id + "/doormapping";
      },
      AddAssignDoor: (userid) => {
        return API_ROUTE + "/Doors/user/" + userid + "/addAssignDoor";
      },
      AddUserToDoor: (id) => {
        return API_ROUTE + "/Doors/" + id + "/addUserToDoor";
      },
      RemoveUserToDoor: (id, userid) => {
        return API_ROUTE + "/Doors/" + id + "/removeUserToDoor/" + userid;
      },
      SyncUserToDoorDevice: (id, userid) => {
        return API_ROUTE + "/Doors/" + id + "/sync/to/device/user/" + userid;
      },
      SyncSelectedUserToDoorDevice: (id) => {
        return API_ROUTE + "/Doors/" + id + "/sync/to/device/selecteduser";
      },
      SyncToDeviceUserAccessParameter: (id, userId) => {
        return (
          API_ROUTE +
          "/Doors/" +
          id +
          "/sync/to/device/user/accessParameter/" +
          userId
        );
      },
      Guest: (id) => {
        return API_ROUTE + "/Doors/" + id + "/guest";
      },
      TerminalDevice: API_ROUTE + "/TerminalDevices",
    },
    Logs: {
      MAIN: API_ROUTE + "/LogAxses",
      Count: API_ROUTE + "/LogAxses/count",
      CountIn: API_ROUTE + "/LogAxses/count/in",
      CountOut: API_ROUTE + "/LogAxses/count/out",
      Lists: API_ROUTE + "/LogAxses/lists",
    },
    Organization: {
      MAIN: API_ROUTE + "/Organizations",
      Register: API_ROUTE + "/Organizations/register",
      ModifyNames: (id) => {
        return API_ROUTE + "/Organizations/" + id + "/modify/names";
      },
      Removed: (id) => {
        return API_ROUTE + "/Organizations/" + id + "/removed";
      },
    },
    AccessGroup: {
      MAIN: API_ROUTE + "/Groups",
      Register: API_ROUTE + "/Groups/add",
      Lists: API_ROUTE + "/Groups/lists",
      ModifyNames: (id) => {
        return API_ROUTE + "/Groups/" + id + "/modify/names";
      },
      Removed: (id) => {
        return API_ROUTE + "/Groups/" + id + "/removed";
      },
      AddDoor: (id) => {
        return API_ROUTE + "/Groups/" + id + "/adddoor/to/group";
      },
      RemovedDoor: (id, doorId) => {
        return API_ROUTE + "/Groups/" + id + "/removedoor/from/group/" + doorId;
      },
    },
  },
  RTAPI: {
    URL: RTAPI_URL,
    User: "axses_system_dash",
    Pass: "asdh@453bia&2sfeeYq3rs",
  },
};
