import { CONSTANTS, NFetch, SERVER } from "../api";

export const DashboardAction = {
  getLatestEntry: getLatestEntry,
};

let wait = null;
async function getLatestEntry(dispatch, count = 10, isWait = false) {
  try {
    if (isWait) {
      if (wait) {
        clearTimeout(wait);
      }

      wait = setTimeout(async () => {
        const result = await NFetch.GetQuery(SERVER.API.Dashboard.LatestEntry, {
          count: count,
        });
        dispatch({ type: CONSTANTS.DASHBOARD.DATA, payload: result });
      }, 5000);
    } else {
      const result = await NFetch.GetQuery(SERVER.API.Dashboard.LatestEntry, {
        count: count,
      });
      dispatch({ type: CONSTANTS.DASHBOARD.DATA, payload: result });
    }
  } catch (error) {
    return Promise.reject(error);
  }
}
