import { CONSTANTS } from "../api/constants";
let initState = {
  error: null,
  message: "",
  data: { adminList: [], staffList: [] },
  selectedId: 0,
  selectedType: 0, // 0 = staff, 1 = admin
  adminData: [],
  staffData: [],
  adminList: [],
  staffList: [],
  pagination: {
    admin: {
      itemPerPage: 10,
      currentPage: 1,
      totalPage: 1,
    },
    staff: {
      itemPerPage: 10,
      currentPage: 1,
      totalPage: 1,
    },
  },
  searchValue: "",
  dataSearch: [],
  adminDataSearch: [],
  adminSearchValue: "",
  staffDataSearch: [],
  staffSearchValue: "",
  count: {
    activeUser: 0,
    adminAddedToday: 0,
    staffAddedToday: 0,
  },
};
export function users(state = initState, action) {
  let __state = {};
  let _j = 0;

  switch (action.type) {
    case CONSTANTS.USER.LIST_FULFILLED:
      let _adminData = [];
      let _staffData = [];

      for (let i = 0; i < action.payload.adminList.length; i++) {
        if (i < 10) {
          _adminData.push(action.payload.adminList[i]);
        }
      }

      _j = 0;
      let totalPageStaff = Math.ceil(
        action.payload.staffList.length / state.pagination.staff.itemPerPage
      );
      let currentPageStaff =
        state.pagination.staff.currentPage === totalPageStaff
          ? state.pagination.staff.currentPage - 1
          : state.pagination.staff.currentPage;
      //   for (let i = 0; i < action.payload.staffList.length; i++) {
      //     // if (i < 10) {
      //     //     _staffData.push(action.payload.staffList[i])
      //     // }

      //     for (
      //       let i = (currentPageStaff - 1) * state.pagination.staff.itemPerPage;
      //       i < action.payload.staffList.length;
      //       i++
      //     ) {
      //       if (_j < state.pagination.staff.itemPerPage) {
      //         _staffData.push(action.payload.staffList[i]);
      //         _j++;
      //       }
      //     }
      //   }

      let _start =
        (state.pagination.staff.currentPage - 1) *
        state.pagination.staff.itemPerPage;
      let _end =
        state.pagination.staff.currentPage * state.pagination.staff.itemPerPage;
      _staffData = action.payload.staffList.slice(_start, _end);

      state = {
        ...state,
        data: action.payload,
        adminData: action.payload.adminList,
        staffData: action.payload.staffList,
        adminList: _adminData,
        staffList: _staffData,
        pagination: {
          admin: {
            itemPerPage: state.pagination.admin.itemPerPage || 10,
            currentPage: state.pagination.admin.currentPage || 1,
            totalPage: Math.ceil(
              action.payload.adminList.length /
                state.pagination.admin.itemPerPage
            ),
          },
          staff: {
            itemPerPage: state.pagination.staff.itemPerPage || 10,
            currentPage: currentPageStaff || 1,
            totalPage: Math.ceil(
              action.payload.staffList.length /
                state.pagination.staff.itemPerPage
            ),
          },
        },
        error: null,
      };
      break;
    case CONSTANTS.USER.LIST_ADD:
      //   console.log(state);
      __state = { ...state };
      if (action.payload.admin === 1) {
        __state.data.adminList.push(action.payload);
        __state.adminData.push(action.payload);
      } else {
        __state.data.staffList.push(action.payload);
        __state.staffData.push(action.payload);
      }

      state = {
        ...__state,
        error: null,
      };

      break;
    case CONSTANTS.USER.REGISTER_FULFILLED:
      state = {
        ...state,
        error: null,
      };
      break;
    case CONSTANTS.USER.SELECTED:
      state = {
        ...state,
        selectedId: action.payload,
      };
      break;
    case CONSTANTS.USER.SELECTED_ADMIN:
      state = {
        ...state,
        selectedId: action.payload,
        selectedType: 1,
      };
      break;
    case CONSTANTS.USER.SELECTED_STAFF:
      state = {
        ...state,
        selectedId: action.payload,
        selectedType: 0,
      };
      break;
    case CONSTANTS.USER.COUNT_CHANGE:
      __state = { ...state };
      __state.pagination.itemPerPage = action.payload;
      __state.pagination.currentPage = 1;
      __state.pagination.totalPage = Math.ceil(
        __state.data.length / action.payload
      );

      __state.dataList = [];

      for (let i = 0; i < __state.data.length; i++) {
        if (i < action.payload) {
          __state.dataList.push(__state.data[i]);
        }
      }

      state = {
        ...__state,
      };
      break;
    case CONSTANTS.USER.ADMIN_COUNT_CHANGE:
      __state = { ...state };

      __state.pagination.admin.itemPerPage = action.payload;
      __state.pagination.admin.currentPage = 1;
      __state.pagination.admin.totalPage = Math.ceil(
        __state.adminData.length / action.payload
      );

      __state.adminList = [];

      for (let i = 0; i < __state.adminData.length; i++) {
        if (i < action.payload) {
          __state.adminList.push(__state.adminData[i]);
        }
      }

      state = {
        ...__state,
      };
      break;
    case CONSTANTS.USER.ADMIN_PAGE_CHANGE:
      __state = { ...state };
      __state.adminList = [];

      __state.pagination.admin.currentPage = action.payload;

      _j = 0;

      if (state.searchValue === "") {
        for (
          let i = (action.payload - 1) * state.pagination.admin.itemPerPage;
          i < __state.adminData.length;
          i++
        ) {
          if (_j < state.pagination.admin.itemPerPage) {
            __state.adminList.push(__state.adminData[i]);
            _j++;
          }
        }
      } else {
        for (
          let i = (action.payload - 1) * state.pagination.admin.itemPerPage;
          i < __state.dataSearch.length;
          i++
        ) {
          if (_j < state.pagination.admin.itemPerPage) {
            __state.adminList.push(__state.dataSearch[i]);
            _j++;
          }
        }
      }

      state = {
        ...__state,
      };
      break;
    case CONSTANTS.USER.STAFF_COUNT_CHANGE:
      __state = { ...state };

      __state.pagination.staff.itemPerPage = action.payload;
      __state.pagination.staff.currentPage = 1;
      __state.pagination.staff.totalPage = Math.ceil(
        __state.staffData.length / action.payload
      );

      __state.staffList = [];

      for (let i = 0; i < __state.staffData.length; i++) {
        if (i < action.payload) {
          __state.staffList.push(__state.staffData[i]);
        }
      }

      state = {
        ...__state,
      };
      break;
    case CONSTANTS.USER.STAFF_PAGE_CHANGE:
      __state = { ...state };
      __state.staffList = [];

      __state.pagination.staff.currentPage = action.payload;

      _j = 0;

      if (state.searchValue === "") {
        for (
          let i = (action.payload - 1) * state.pagination.staff.itemPerPage;
          i < __state.staffData.length;
          i++
        ) {
          if (_j < state.pagination.staff.itemPerPage) {
            __state.staffList.push(__state.staffData[i]);
            _j++;
          }
        }
      } else {
        for (
          let i = (action.payload - 1) * state.pagination.staff.itemPerPage;
          i < __state.dataSearch.length;
          i++
        ) {
          if (_j < state.pagination.staff.itemPerPage) {
            __state.staffList.push(__state.dataSearch[i]);
            _j++;
          }
        }
      }

      state = {
        ...__state,
      };
      break;
    case CONSTANTS.USER.STAFF_SEARCH:
      __state = { ...state };
      __state.staffList = [];
      __state.staffDataSearch = [];

      __state.pagination.staff.currentPage = 1;
      __state.staffSearchValue = action.payload;

      const searchValue = action.payload.toLowerCase();
      state.staffData.forEach((element) => {
        if (element.names.toLowerCase().includes(searchValue)) {
          __state.staffDataSearch.push(element);
        }
        if (searchValue === "admin" && element.admin === 1) {
          __state.staffDataSearch.push(element);
        } else if (searchValue === "user" && element.admin === 0) {
          __state.staffDataSearch.push(element);
        }
      });

      __state.pagination.staff.totalPage = Math.ceil(
        __state.staffDataSearch.length / state.pagination.staff.itemPerPage
      );

      _j = 0;
      for (let i = 0; i < __state.staffDataSearch.length; i++) {
        if (_j < state.pagination.staff.itemPerPage) {
          __state.staffList.push(__state.staffDataSearch[i]);
          _j++;
        }
      }

      state = {
        ...__state,
      };

      break;
    case CONSTANTS.USER.ADMIN_SEARCH:
      __state = { ...state };
      __state.adminList = [];
      __state.adminDataSearch = [];

      __state.pagination.admin.currentPage = 1;
      __state.adminSearchValue = action.payload;

      state.adminData.forEach((element) => {
        if (element.names.includes(action.payload)) {
          __state.adminDataSearch.push(element);
        }
      });

      __state.pagination.admin.totalPage = Math.ceil(
        __state.adminDataSearch.length / state.pagination.admin.itemPerPage
      );

      _j = 0;
      for (let i = 0; i < __state.adminDataSearch.length; i++) {
        if (_j < state.pagination.admin.itemPerPage) {
          __state.adminList.push(__state.adminDataSearch[i]);
          _j++;
        }
      }

      state = {
        ...__state,
      };

      break;
    case CONSTANTS.USER.COUNT_FULFILLED:
      __state = { ...state };
      __state.count = {
        activeUser: action.payload.total,
        adminAddedToday: action.payload.adminToday,
        staffAddedToday: action.payload.staffToday,
      };
      state = {
        ...__state,
      };
      break;

    case CONSTANTS.USER.STAFF_UPDATE_INFO:
      // action.payload = {id: <>, ...}
      // data = action.payload
      const findIndex1 = state.staffData.findIndex(
        (item) => item.id === action.payload.id
      );
      const findIndex2 = state.staffList.findIndex(
        (item) => item.id === action.payload.id
      );

      if (findIndex1 === -1) {
        return;
      }

      __state = { ...state };
      __state.staffData[findIndex1] = {
        ...__state.staffData[findIndex1],
        ...action.payload,
      };

      if (findIndex2 !== -1) {
        __state.staffList[findIndex2] = {
          ...__state.staffList[findIndex2],
          ...action.payload,
        };
      }

      state = {
        ...__state,
      };
      break;
    case CONSTANTS.CLEAR:
      return initState;
    default:
      return state;
  }
  return state;
}
