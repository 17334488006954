import { CONSTANTS, NFetch, SERVER } from "../api";
import Dates from "../__ifunc/dates";

export const LogAction = {
  logList: logList,
  countAll: countAll,
  todayList: todayList,
};

async function logList(dispatch, param = null) {
  try {
    if (param === null) {
      let dates = Dates.format(new Date(), Dates.FORMAT.DATE1);
      param = { startDate: dates, endDate: dates };
    }
    const result = await NFetch.GetQuery(SERVER.API.Logs.Lists, param);
    dispatch({ type: CONSTANTS.LOGS.LIST_FULFILLED, payload: result });
  } catch (error) {
    return Promise.reject(error);
  }
}

async function countAll(dispatch) {
  try {
    const overall = await NFetch.GetQuery(SERVER.API.Logs.Count);
    const countIn = await NFetch.GetQuery(SERVER.API.Logs.Count);
    const countOut = await NFetch.GetQuery(SERVER.API.Logs.Count);

    const _data = {
      overall: overall.count,
      totalIn: countIn.count,
      totalOut: countOut.count,
    };

    dispatch({ type: CONSTANTS.LOGS.COUNT_FULFILLED, payload: _data });
  } catch (error) {
    return Promise.reject(error);
  }
}

let wait = null;
async function todayList(dispatch, isWait = false) {
  try {
    if (isWait) {
      if (wait) {
        clearTimeout(wait);
      }

      wait = setTimeout(async () => {
        const result = await NFetch.GetWithFilter(SERVER.API.Doors.TodayList);
        dispatch({
          type: CONSTANTS.LOGS.TODAY_LIST_FULFILLED,
          payload: result,
        });
      }, 5000);
    } else {
      const result = await NFetch.GetWithFilter(SERVER.API.Doors.TodayList);
      dispatch({ type: CONSTANTS.LOGS.TODAY_LIST_FULFILLED, payload: result });
    }
  } catch (error) {
    return Promise.reject(error);
  }
}
