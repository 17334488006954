import { CONSTANTS, NFetch, SERVER } from "../api";
export const DoorAction = {
  register: register,
  updates: updates,
  openDoor: openDoor,
  deletes: deletes,
  getList: getList,
  getListUsers: getListUsers,
  summaryInfo: summaryInfo,
  reCountUser: reCountUser,
  getAccessParameter: getAccessParameter,
  getNormalOpenTime: getNormalOpenTime,
  onUpdateToDevice: onUpdateToDevice,
  modifyLocation: modifyLocation,
  modifySerialId: modifySerialId,

  addAssignDoor: addAssignDoor,
  addUserToDoor: addUserToDoor,
  removeUserToDoor: removeUserToDoor,
  syncUserToDoorDevice: syncUserToDoorDevice,
  syncSelectedUserToDoorDevice: syncSelectedUserToDoorDevice,
  syncToDeviceUserAccessParameter: syncToDeviceUserAccessParameter,

  enableGuest,
  infoGuest,
};

function register(data) {
  return NFetch.Post(SERVER.API.Doors.Register, data);
}
function updates(id, data) {
  return NFetch.Post(SERVER.API.Doors.Modify(id), data);
}
function modifyLocation(id, location) {
  return NFetch.Post(SERVER.API.Doors.ModifyLocation(id), {
    location: location,
  });
}
function modifySerialId(id, terminalId, serialId) {
  return NFetch.Post(SERVER.API.Doors.ModifySerialId(id, terminalId), {
    serialId: serialId,
  });
}
function openDoor(id) {
  return NFetch.Post(SERVER.API.Doors.OpenDoor(id));
}
function deletes(id) {
  return NFetch.Post(SERVER.API.Doors.RemoveDoor(id));
}
async function getList(dispatch) {
  try {
    const result = await NFetch.GetQuery(SERVER.API.Doors.List);
    dispatch({ type: CONSTANTS.DOORS.LIST_FULFILLED, payload: result });
  } catch (error) {
    return Promise.reject(error);
  }
}
function getListUsers(doorid) {
  return NFetch.Get(SERVER.API.Doors.ListUsers(doorid));
}
async function summaryInfo(dispatch) {
  try {
    const countDoorActive = await NFetch.GetCount(SERVER.API.Doors.Count, {
      doorActivation: true,
    });
    const countDoorAlert = await NFetch.GetCount(SERVER.API.Doors.Count, {
      isAlert: true,
    });
    const countDoorOpen = await NFetch.GetCount(SERVER.API.Doors.Count, {
      isOpen: true,
    });

    const terminalDevice = await NFetch.GetWithFilter(
      SERVER.API.Doors.TerminalDevice,
      { fields: ["isOnline", "doorId"] }
    );
    // console.log(terminalDevice)

    let doors = {};
    for (let i = 0; i < terminalDevice.length; i++) {
      if (terminalDevice[i].doorId === null) {
        continue;
      }
      if (typeof doors[terminalDevice[i].doorId] === "undefined") {
        doors[terminalDevice[i].doorId] = terminalDevice[i].isOnline;
        if (terminalDevice[i].isOnline === null) {
          doors[terminalDevice[i].doorId] = false;
        }
      } else {
        if (terminalDevice[i].isOnline === null) {
          doors[terminalDevice[i].doorId] = false;
        } else {
          doors[terminalDevice[i].doorId] =
            doors[terminalDevice[i].doorId] && terminalDevice[i].isOnline;
        }
      }
    }
    let totalOffline = 0;
    for (const key in doors) {
      if (doors[key] === false) {
        totalOffline++;
      }
    }

    const _data = {
      activeDoor: countDoorActive.count,
      activeAlert: countDoorAlert.count + totalOffline,
      openedDoor: countDoorOpen.count,
    };

    dispatch({ type: CONSTANTS.DOORS.COUNT_FULFILLED, payload: _data });
  } catch (error) {
    return Promise.reject(error);
  }
}
async function reCountUser(dispatch, doorId, currentCount) {
  try {
    const result = await NFetch.Get(
      SERVER.API.Doors.ReCountUser(doorId, currentCount)
    );
    dispatch({
      type: CONSTANTS.DOORS.UPDATE_INFO,
      payload: { id: doorId, info: { totaluser: result.count } },
    });
  } catch (error) {
    return Promise.reject(error);
  }
}
function getAccessParameter(doorId) {
  return NFetch.Get(SERVER.API.Doors.AccessParameter(doorId));
}
function getNormalOpenTime(doorId) {
  return NFetch.Get(SERVER.API.Doors.NormalOpenTime(doorId));
}
function onUpdateToDevice(id, data) {
  return NFetch.Post(SERVER.API.Doors.ParameterInfo(id), data);
}

function addAssignDoor(profileUserId, listDoors) {
  return NFetch.Post(SERVER.API.Doors.AddAssignDoor(profileUserId), {
    door: listDoors,
  });
}
function addUserToDoor(doorId, listUsers) {
  return NFetch.Post(SERVER.API.Doors.AddUserToDoor(doorId), {
    user: listUsers,
  });
}
function removeUserToDoor(doorId, profileUserId) {
  return NFetch.Post(SERVER.API.Doors.RemoveUserToDoor(doorId, profileUserId));
}
function syncUserToDoorDevice(doorId, profileUserId) {
  return NFetch.Post(
    SERVER.API.Doors.SyncUserToDoorDevice(doorId, profileUserId)
  );
}
function syncSelectedUserToDoorDevice(doorId, listUser) {
  return NFetch.Post(SERVER.API.Doors.SyncSelectedUserToDoorDevice(doorId), {
    user: listUser,
  });
}
function syncToDeviceUserAccessParameter(doorId, userId) {
  return NFetch.Post(
    SERVER.API.Doors.SyncToDeviceUserAccessParameter(doorId, userId)
  );
}

function enableGuest(doorId, data) {
  return NFetch.Post(SERVER.API.Doors.Guest(doorId), data);
}
function infoGuest(doorId) {
  return NFetch.Get(SERVER.API.Doors.Guest(doorId));
}
