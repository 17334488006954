import { CONSTANTS } from "../api/constants";
let initState = {
  error: null,
  message: "",
  data: [],
  selectedId: 0,
  dataList: [],
  pagination: {
    itemPerPage: 10,
    currentPage: 1,
    totalPage: 1,
  },
  searchValue: "",
  dataSearch: [],
  count: {
    activeDoor: 0,
    activeAlert: 0,
    openedDoor: 0,
  },
};
export function doors(state = initState, action) {
  let __state = {};
  switch (action.type) {
    case CONSTANTS.DOORS.LIST_FULFILLED:
      let _data = [];

      // console.log(action.payload)

      for (let i = 0; i < action.payload.length; i++) {
        if (i < 10) {
          _data.push(action.payload[i]);
        }
      }

      __state = {
        ...state,
        data: action.payload,
        dataList: _data,
        pagination: {
          itemPerPage: 10,
          currentPage: 1,
          totalPage: Math.ceil(
            action.payload.length / state.pagination.itemPerPage
          ),
        },
      };

      break;
    case CONSTANTS.DOORS.UPDATE_INFO:
      __state = { ...state };

      for (let i = 0; i < state.data.length; i++) {
        if (state.data[i].id === action.payload.id) {
          let __data = state.data;
          __data[i] = { ...state.data[i], ...action.payload.info };
          __state.data = __data;
          break;
        }
      }

      __state.dataList = [];
      let _j__ = 0;
      for (
        let i =
          (__state.pagination.currentPage - 1) * state.pagination.itemPerPage;
        i < __state.data.length;
        i++
      ) {
        if (_j__ < state.pagination.itemPerPage) {
          __state.dataList.push(__state.data[i]);
          _j__++;
        }
      }

      break;
    case CONSTANTS.DOORS.LIST_ADD:
      __state = { ...state };
      __state.data.push(action.payload);
      __state.dataList.push(action.payload);
      __state.pagination = {
        itemPerPage: 10,
        currentPage: 1,
        totalPage: Math.ceil(
          (state.data.length + 1) / state.pagination.itemPerPage
        ),
      };

      break;
    case CONSTANTS.DOORS.LIST_REJECTED:
      __state = {
        ...state,
        error: null,
      };
      break;
    case CONSTANTS.DOORS.SELECTED:
      __state = {
        ...state,
        selectedId: action.payload,
      };
      break;
    case CONSTANTS.DOORS.COUNT_CHANGE:
      __state = { ...state };
      __state.pagination.itemPerPage = action.payload;
      __state.pagination.currentPage = 1;
      __state.pagination.totalPage = Math.ceil(
        __state.data.length / action.payload
      );

      __state.dataList = [];
      for (let i = 0; i < __state.data.length; i++) {
        if (i < action.payload) {
          __state.dataList.push(__state.data[i]);
        }
      }
      break;
    case CONSTANTS.DOORS.PAGE_CHANGE:
      __state = { ...state };
      __state.pagination.currentPage = action.payload;

      __state.dataList = [];
      let _j = 0;
      for (
        let i = (action.payload - 1) * state.pagination.itemPerPage;
        i < __state.data.length;
        i++
      ) {
        if (_j < state.pagination.itemPerPage) {
          __state.dataList.push(__state.data[i]);
          _j++;
        }
      }
      break;
    case CONSTANTS.DOORS.SEARCH:
      __state = { ...state };
      __state.dataList = [];
      __state.dataSearch = [];

      __state.pagination.currentPage = 1;
      __state.pagination.searchValue = action.payload;

      // console.log(state.data)
      state.data.forEach((element) => {
        if (element.location.includes(action.payload)) {
          __state.dataSearch.push(element);
        } else if (typeof element.enter !== "undefined") {
          if (element.enter.serialNumber.includes(action.payload)) {
            __state.dataSearch.push(element);
          }
        } else if (typeof element.exit !== "undefined") {
          if (element.exit.serialNumber.includes(action.payload)) {
            __state.dataSearch.push(element);
          }
        }
      });

      __state.pagination.totalPage = Math.ceil(
        __state.dataSearch.length / state.pagination.itemPerPage
      );

      _j = 0;
      for (let i = 0; i < __state.dataSearch.length; i++) {
        if (_j < state.pagination.itemPerPage) {
          __state.dataList.push(__state.dataSearch[i]);
          _j++;
        }
      }
      break;
    case CONSTANTS.DOORS.COUNT_FULFILLED:
      __state = { ...state };
      __state.count = action.payload;
      break;
    case CONSTANTS.DOORS.OFFLINE:
      __state = { ...state };
      const door = state.data.find((data) => {
        return data.id === action.payload.doorId;
      });
      if (typeof door !== "undefined") {
        const index = state.data.findIndex((element) => {
          return element.id === door.id;
        });
        let currentOnline = true;
        let isOnline = true;
        for (let i = 0; i < door.terminalDevice.length; i++) {
          currentOnline &= door.terminalDevice[i].isOnline;
          if (door.terminalDevice[i].id === action.payload.id) {
            __state.data[index].terminalDevice[i].isOnline = false;
          }
          isOnline &= __state.data[index].terminalDevice[i].isOnline;
        }
        if (currentOnline !== isOnline) {
          __state.count.activeAlert++;
        }
      }

      break;
    case CONSTANTS.DOORS.ONLINE:
      __state = { ...state };
      // console.log('Total Alarm before Online', __state.count.activeAlert)
      const door2 = state.data.find((data) => {
        return data.id === action.payload.doorId;
      });
      if (typeof door2 !== "undefined") {
        const index = state.data.findIndex((element) => {
          return element.id === door2.id;
        });
        let currentOnline = true;
        let isOnline = true;
        for (let i = 0; i < door2.terminalDevice.length; i++) {
          currentOnline &= door2.terminalDevice[i].isOnline;
          if (door2.terminalDevice[i].id === action.payload.id) {
            __state.data[index].terminalDevice[i].isOnline = true;
          }
          isOnline &= __state.data[index].terminalDevice[i].isOnline;
          // console.log(__state.data[index].terminalDevice[i].isOnline, __state.data[index].terminalDevice[i].id)
        }
        // console.log(isOnline, door2.id)
        if (isOnline && !currentOnline) {
          __state.count.activeAlert--;
        }
      }

      // console.log('Total Alarm after Online', __state.count.activeAlert)
      break;

    case CONSTANTS.DOORS.TRIGGER:
      __state = { ...state };
      const door_trigger = state.data.find((data) => {
        return data.id === action.payload.doorId;
      });
      if (typeof door_trigger !== "undefined") {
        const index = state.data.findIndex((element) => {
          return element.id === door_trigger.id;
        });
        const hasTrigger =
          state.data[index].isAlert && state.data[index].isAlert;
        __state.data[index].isAlert = true;
        __state.data[index].isOpen = true;

        if (!hasTrigger) {
          __state.count.activeAlert++;
        }
      }
      break;

    case CONSTANTS.DOORS.DISMISS:
      __state = { ...state };
      const door_dismiss = state.data.find((data) => {
        return data.id === action.payload.doorId;
      });
      if (typeof door_dismiss !== "undefined") {
        const index = state.data.findIndex((element) => {
          return element.id === door_dismiss.id;
        });
        __state.data[index].isAlert = false;
        __state.data[index].isOpen = false;

        __state.count.activeAlert--;
      }
      break;

    case CONSTANTS.CLEAR:
      return initState;
    default:
      return state;
  }
  return __state;
}
